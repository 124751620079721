<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <div>

      <div class="stop-all">
        <h3>Остановить все</h3>
        <button class="play-stop-btn" @click="soundStopAll()">⏸</button>
      </div>

      <ul class="tracks__list">
        <li class="tracks-list__item" v-for="track,index in tracks" :key="index">
          <h3>{{ track.name }}</h3>
          <div class="btn-wrap">
            <buttom v-if="!track.playState" class="play-stop-btn" @click="soundPlay(track.id, track.playState)">▶️</buttom>
            <buttom v-if="track.playState" class="play-stop-btn" @click="soundStop(track.id, track.playState)">⏸</buttom>
          </div>
          <input type="range" v-if="track.playState" v-model="track.volume" @change="changeVolume(track.id)">
        </li>
      </ul>
      
  
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'




export default {
  name: 'App',
  data() {
    return {
      // audio: [],
      tracks: {
        rain: {
            id: 'rain',
            name: '🌧 Дождь',
            audio: new Audio('http://sound.radantsev.ru/rain.mp3'),
            playState: false,
            loop: true,
            volume: 20
          },
        fire: {
          id: 'fire',
          name: '🔥 Огонь',
          audio: new Audio('http://sound.radantsev.ru/fire.mp3'),
          playState: false,
          loop: true,
          volume: 20
        },
        barista: {
          id: 'barista',
          name: '👱🏻‍♀️ Бариста в кафе',
          audio: new Audio('http://sound.radantsev.ru/barista.mp3'),
          playState: false,
          loop: true,
          volume: 20
        },
        library: {
          id: 'library',
          name: '📚 Библиотека',
          audio: new Audio('http://sound.radantsev.ru/library.mp3'),
          playState: false,
          loop: true,
          volume: 20
        },
      }
    }
  },
  methods: {
    soundPlay(trackId, trackPlayState) {
      this.tracks[trackId].audio.play();
      this.tracks[trackId].audio.loop = true;
      this.tracks[trackId].audio.volume = this.tracks[trackId].volume/100;
      this.tracks[trackId].playState = !trackPlayState;
    },
    soundStop(trackId, trackPlayState)  {
      this.tracks[trackId].audio.pause();
      // this.tracks[trackId].audio.loop = true;
      this.tracks[trackId].audio.volume = this.tracks[trackId].volume/100;
      this.tracks[trackId].playState = !trackPlayState;
    },
    changeVolume(trackId) {
      this.tracks[trackId].audio.volume = this.tracks[trackId].volume/100;
    },
    soundStopAll() {
      for (let key in this.tracks) {

        this.tracks[key].audio.pause();
        this.tracks[key].playState = false;

        // for (let item in this.tracks[key]) {
        //   console.log(item);
        // }
      }
    }
  },
  created() {
    // this.audio = new Audio();
  }

  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .tracks__list {
    padding: 0;
    margin-top: 100px;
  }

  .tracks-list__item {
    list-style-type: none;
    margin-bottom: 50px;
  }

  .play-stop-btn {
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
  }
</style>
